<template>
  <b-container fluid>
    <b-row class="justify-content-center mt-5">
      <b-col xs="12" sm="8" md="8" lg="4">
        <b-card class="mt-3">
          <b-card-body id="forgotPassword" class="pt-3">
            <b-row class="pb-4">
              <b-col class="text-center">
                <b-img src="@/assets/dark1.svg"></b-img>
              </b-col>
            </b-row>
            <b-row v-if="!submitted"  class="pb-4">
              <b-col class="text-center"
                >Le enviaremos un mail de recuperacion a su correo</b-col
              >
            </b-row>
            <b-row class="pb-4">
              <b-col>
                <div>
                  <p v-if="submitted">
                    Hemos enviado un mensaje de recuperación a tu cuenta de
                    email. Verificá tu bandeja de entrada.
                  </p>
                  <b-form v-if="!submitted">
                    <b-form-group
                      id="input-group-1"
                      :description="emailDesc"
                      class="pb-3"
                    >
                      <b-form-input
                        :state="emailState"
                        placeholder="Su email"
                        id="input-1"
                        type="email"
                        v-model.lazy="$v.form.email.$model"
                        required
                      ></b-form-input>
                    </b-form-group>

                    <b-button
                      block
                      @click.prevent="doReset"
                      size="lg"
                      variant="primary"
                      :disabled="!$v.$anyDirty || $v.$anyError || this.loading"
                    >
                      <span class="form-text">ENVIAR</span>
                      <b-spinner
                        type="grow"
                        v-if="this.loading"
                        label="Loading..."
                      ></b-spinner>
                    </b-button>
                  </b-form>
                </div>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="text-center">
      <b-col>
        <span class="text-muted copyright"
          >Bithan ©2021 Todos los derechos reservados</span
        >
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { required, email } from "vuelidate/lib/validators"
import constants from "@/constants"
const WITHOUT_ERROR = constants.WITHOUT_ERROR_CODE

export default {
  name: "ForgotPassword",

  data() {
    return {
      loading: false,
      submitted: false,
      form: {
        email: "",
      },
    }
  },

  methods: {
    async doReset() {
      try {
        this.loading = true
        const email = this.$v.form.email.$model
        const company_id = "1"
        this.$api
          .getForgotPasswordToken(company_id, email)
          .then((response) => {

            if(response.data.Error === WITHOUT_ERROR) {
                this.successNotify()
            } else {
              this.errorNotify(response.data.message)
            }
          })
          .catch((err) => {
            console.log(err)
            this.errorNotify("Error al enviar el mail.")
          })
          .finally(() => {
            this.loading = false
          })
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err)
      }
    },
    errorNotify(msg) {
      const notification = {
        message: msg,
        variant: "danger",
        countdown: 5,
      }
      this.$store.dispatch("notifications/notify", {
        notification: notification,
      })
    },
    successNotify() {
      const notification = {
        message: "Se envió un mail con instrucciones.",
        variant: "success",
        countdown: 5,
      }
      this.$store.dispatch("notifications/notify", {
        notification: notification,
      })
    }
  },

  computed: {
    formTitle: function () {
      return this.submitted
        ? "Por favor revisa tu email"
        : "Recuperar contraseña"
    },
    emailState: function () {
      return this.emailDesc === "" ? true : null
    },
    emailDesc: function () {
      const valid = !this.$v.form.email.$invalid && this.$v.form.email.$dirty
      return !valid ? "Ingrese un email válido" : ""
    },
  },

  validations: {
    form: {
      email: {
        required,
        email,
      },
    },
  },
  beforeMount() {
    this.$v.form.email.$model = this.$store.getters["user/email"] || ""
  },
}
</script>

<style lang="css" scoped>
.signup {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 5% 0;
}
</style>
